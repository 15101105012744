.first-fold-b {
	height: 100vh;
	width: 100vw;
	background-image: url("../../assets/images/bottom_img.png");
	background-color: #cccccc;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	color: #fff;
	// margin-top: 80px;
	& > .main-content {
		background-color: #0000006b;
		height: 100vh;
		width: 100vw;
		position: absolute;
		top: 0;
	}
	& > .bottom-content {
		height: 100vh;
		width: 100vw;
		justify-content: space-between;
		width: 100%;
		align-items: center;
		display: flex;
		color: #fff;
		padding: 5%;
		& > .left-content {
			width: 50%;
			position: relative;
			display: flex;
			align-items: center;
			flex-flow: column;
			letter-spacing: 1px;
			// padding: 0 100px;
			border-right: 1px solid #fff;
			height: 500px;
			.comp-name {
				margin-right: 100px;
				& > .comp-text {
					margin-bottom: 20px;
				}
			}
			& > .btn-bottom {
				width: 100%;
				& > .know-btn {
					margin-top: 50px;
					background-color: transparent;
					color: #fff;
					border: 1px solid #fff;
					padding: 10px 20px;
					left: 0;
				}
			}
			& > .know-btn:hover {
				background-color: #fff;
				color: #030303;
				border: 1px solid #333;
				box-shadow: 12px 12px 2px 1px #85858933;
			}
		}
		& > .right-content {
			width: 50%;
			font-size: 14px;
			letter-spacing: 1px;
			height: 500px;
			display: flex;
			flex-flow: column;
			align-items: center;
			justify-content: center;
			padding-left: 100px;
			& > .c-name {
				margin-top: 50px;
				font-weight: bold;
				width: 100%;
			}
		}
	}
}

.left-sec {
	margin-top: 100px;
}

/* Slideshow */

.slideshow {
	margin: 0 auto;
	overflow: hidden;
	// max-width: 500px;
	width: 100%;
}

.slideshowSlider {
	white-space: nowrap;
	transition: ease 1000ms;
}

.slide {
	display: inline-block;

	// height: 400px;
	width: 100%;
	border-radius: 40px;
}

/* Buttons */

.slideshowDots {
	text-align: center;
}

.slideshowDot {
	display: inline-block;
	height: 20px;
	width: 20px;
	border-radius: 50%;

	cursor: pointer;
	margin: 15px 7px 0px;

	background-color: #c4c4c4;
}

.slideshowDot.active {
	background-color: #6a0dad;
}
