.textcontent {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 260px;
	overflow: hidden;
	z-index: 10;
	display: flex;
	flex-flow: column;
	width: 100%;
	&_text {
		overflow: hidden;
		height: 90px;
		padding: 0 80px;
		letter-spacing: 24px;
		font-size: 73px;
		margin: 0;
		font-weight: 300;
		color: #fff;
		text-align: center;
	}
	&__container {
		overflow: hidden;
		height: 120px;
		padding: 0 80px;
		letter-spacing: 24px;
		font-size: 73px;
		&__text {
			display: inline;
			float: left;
			margin: 0;
			font-weight: 300;
			color: #fff;
		}

		&__list {
			margin-top: 15px;
			// padding-left: 440px;
			text-align: left;
			list-style: none;
			color: #b3282b !important;
			font-weight: bold !important;

			-webkit-animation-name: change;
			-webkit-animation-duration: 15s;
			-webkit-animation-iteration-count: infinite;
			animation-name: change;
			animation-duration: 15s;
			animation-iteration-count: infinite;

			&__item {
				line-height: 105px;
				margin: 0;
				text-align: center;
			}
		}
	}
}

@-webkit-keyframes opacity {
	0%,
	100% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
}

@-webkit-keyframes change {
	0%,
	12.66%,
	100% {
		transform: translate3d(0, 0, 0);
	}
	16.66%,
	29.32% {
		transform: translate3d(0, -25%, 0);
	}
	33.32%,
	45.98% {
		transform: translate3d(0, -50%, 0);
	}
	49.98%,
	62.64% {
		transform: translate3d(0, -75%, 0);
	}
	66.64%,
	79.3% {
		transform: translate3d(0, -50%, 0);
	}
	83.3%,
	95.96% {
		transform: translate3d(0, -25%, 0);
	}
}

@-o-keyframes opacity {
	0%,
	100% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
}

@-o-keyframes change {
	0%,
	12.66%,
	100% {
		transform: translate3d(0, 0, 0);
	}
	16.66%,
	29.32% {
		transform: translate3d(0, -25%, 0);
	}
	33.32%,
	45.98% {
		transform: translate3d(0, -50%, 0);
	}
	49.98%,
	62.64% {
		transform: translate3d(0, -75%, 0);
	}
	66.64%,
	79.3% {
		transform: translate3d(0, -50%, 0);
	}
	83.3%,
	95.96% {
		transform: translate3d(0, -25%, 0);
	}
}

@-moz-keyframes opacity {
	0%,
	100% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
}

@-moz-keyframes change {
	0%,
	12.66%,
	100% {
		transform: translate3d(0, 0, 0);
	}
	16.66%,
	29.32% {
		transform: translate3d(0, -25%, 0);
	}
	33.32%,
	45.98% {
		transform: translate3d(0, -50%, 0);
	}
	49.98%,
	62.64% {
		transform: translate3d(0, -75%, 0);
	}
	66.64%,
	79.3% {
		transform: translate3d(0, -50%, 0);
	}
	83.3%,
	95.96% {
		transform: translate3d(0, -25%, 0);
	}
}

@keyframes opacity {
	0%,
	100% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
}

@keyframes change {
	0%,
	12.66%,
	100% {
		transform: translate3d(0, 0, 0);
	}
	16.66%,
	29.32% {
		transform: translate3d(0, -25%, 0);
	}
	33.32%,
	45.98% {
		transform: translate3d(0, -50%, 0);
	}
	49.98%,
	62.64% {
		transform: translate3d(0, -75%, 0);
	}
	66.64%,
	79.3% {
		transform: translate3d(0, -50%, 0);
	}
	83.3%,
	95.96% {
		transform: translate3d(0, -25%, 0);
	}
}

// 6 is the number of animation.
// Here, there are 4 lines :

// 1 to 2
// 2 to 3
// 3 to 4
// 4 to 3
// 3 to 2
// 2 to 1

// 6x + 6y = 100 (100% duration)

// HERE :
// y = 4 -> Animation between two lines
// x = 12.66 -> Time spent on a line

// You can define a value and calculate the other if you want change speed or the number of lines
