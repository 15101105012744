.swiper-container {
	width: 100%;
	height: 90%;
}
.swiper-slide {
	text-align: center;
	background: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.gallery-wrap {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 70vh;
}

.image-item {
	flex: 1;
	height: 100%;
	background-position: center;
	background-size: cover;
	background-repeat: none;
	transition: all 0.8s ease;
	.c-desc {
		display: none;
	}
	&:hover {
		flex: 2;
		.c-desc {
			display: block;
		}
	}
	& > .content {
		text-align: left;
		padding: 50px;
		height: 100%;
		background-color: #030303;
		opacity: 0.5;
		color: #fff;
		& > h4 {
			font-size: 24px;
			font-weight: 600;
		}
	}
}
​
// Image Accordions Effect 1
.wrap-effect {
	.image-item {
		& > :first-of-type {
			background-image: url("https://cdn.dribbble.com/users/729829/screenshots/4185141/galshir-cactus-coffee.png");
		}
		&:nth-of-type(2) {
			background-image: url("https://cdn.dribbble.com/users/729829/screenshots/6146136/galshir-tea-biscuit_2x.png");
		}
		&:nth-of-type(3) {
			background-image: url("https://cdn.dribbble.com/users/729829/screenshots/4473482/galshir-dog-walker.gif");
		}
		&:nth-of-type(4) {
			background-image: url("https://cdn.dribbble.com/users/729829/screenshots/5743606/gal-shir-scooter_2x.png");
		}
		&:last-of-type {
			background-image: url("https://cdn.dribbble.com/users/729829/screenshots/4738104/galshir-storytime.png");
		}
	}
}

.image-item:first-of-type {
	background-image: url("../../assets/images/services/services2.jpg");
}
.image-item:nth-of-type(2) {
	background-image: url("../../assets/images/services/services3.jpg");
}
.image-item:nth-of-type(3) {
	background-image: url("../../assets/images/services/services5.jpg");
}
.image-item:last-of-type {
	background-image: url("../../assets/images/services/services1.jpg");
}

.know-more-btn {
	position: absolute;
	bottom: 100px;
	margin-top: 50px;
	background-color: transparent;
	color: #fff;
	border: 1px solid #fff;
	padding: 10px 20px;
}
.know-more-btn:hover {
	background-color: #fff;
	color: #030303;
	border: 1px solid #333;
	box-shadow: 12px 12px 2px 1px #85858933;
}






