.footer-dark {
	padding: 50px 0;
	color: #f0f9ff;
	background-color: #282d32;
}

.footer-dark h3 {
	margin-top: 0;
	margin-bottom: 12px;
	font-weight: bold;
	font-size: 16px;
}

.row {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.footer-dark ul {
	padding: 0;
	list-style: none;
	line-height: 1.6;
	font-size: 14px;
	margin-bottom: 0;
}

.footer-dark ul a {
	color: inherit;
	text-decoration: none;
	opacity: 0.6;
}

.footer-dark ul a:hover {
	opacity: 0.8;
}

.footer-dark .item.f-text {
	margin-bottom: 36px;
}

@media (max-width: 767px) {
	.footer-dark .item.f-text {
		margin-bottom: 0;
	}
}

.footer-dark .item.f-text p {
	opacity: 0.6;
	margin-bottom: 0;
}

.footer-dark .item.social {
	text-align: center;
}

@media (max-width: 991px) {
	.footer-dark .item.social {
		text-align: center;
		margin-top: 20px;
	}
}

.footer-dark .item.social > a {
	font-size: 20px;
	width: 36px;
	height: 36px;
	line-height: 36px;
	display: inline-block;
	text-align: center;
	border-radius: 50%;
	box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.4);
	margin: 0 8px;
	color: #fff;
	opacity: 0.75;
}

.footer-dark .item.social > a:hover {
	opacity: 0.9;
}

.footer-dark .copyright {
	padding-top: 50px;
	opacity: 0.3;
	font-size: 13px;
	margin-bottom: 0;
	color: #fff;
}




/* **********************************************
                Mobile view
**************************************************/
@media (max-width: 767px) {
	.footer-dark .item:not(.social) {
		text-align: left;
		padding-bottom: 20px;
	}
	.row {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		margin-left:0px !important;
		&>.item{
			margin-left:30px;
		}
	}
}