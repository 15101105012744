.example {
	cursor: pointer;
	position: relative;
	overflow: hidden;
	width: 25%;
	text-align: center;
}
.example .fadedbox-s {
	background-color: #000;
	position: absolute;
	top: 0;
	left: 0;
	color: #fff;
	-webkit-transition: all 300ms ease-out;
	-moz-transition: all 300ms ease-out;
	-o-transition: all 300ms ease-out;
	-ms-transition: all 300ms ease-out;
	transition: all 300ms ease-out;
	opacity: 0;
	width: 360px;
	height: 100px;
	padding: 125px 20px;
}
.example:hover .fadedbox-s {
	opacity: 0.8;
}
.example img {
	transition: transform 0.5s ease;
}
.example:hover img {
	transform: scale(1.2);
}
.example .text {
	-webkit-transition: all 300ms ease-out;
	-moz-transition: all 300ms ease-out;
	-o-transition: all 300ms ease-out;
	-ms-transition: all 300ms ease-out;
	transition: all 300ms ease-out;
	transform: translateY(30px);
	-webkit-transform: translateY(30px);
}
.example .title {
	font-size: 2em;
	text-transform: uppercase;
	// opacity: 0;
	transition-delay: 0.2s;
	transition-duration: 0.3s;
}
.example:hover .title,
.example:focus .title {
	opacity: 1;
	transform: translateY(0px);
	-webkit-transform: translateY(0px);
}

.some-image {
	transition: opacity 0.5s linear;
	-webkit-transition: opacity 0.5s linear;
	-o-transition: opacity 0.5s linear;
	-moz-transition: opacity 0.5s linear;
	animation: fade-out;
	animation-duration: 1s;
}

.hidden {
	opacity: 0;
}

.hidden {
	animation: fade-out;
	animation-duration: 1s;
}

@keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fade-out {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

.row {
	display: -ms-flexbox; /* IE10 */
	display: flex;
	-ms-flex-wrap: wrap; /* IE10 */
	flex-wrap: wrap;
	padding: 0 4px;
}

/* Create four equal columns that sits next to each other */
.column {
	-ms-flex: 25%; /* IE10 */
	flex: 25%;
	max-width: 25%;
	padding: 0 4px;
}

.column img {
	margin-top: 8px;
	vertical-align: middle;
	width: 100%;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
	.column {
		-ms-flex: 50%;
		flex: 50%;
		max-width: 50%;
	}
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
	.column {
		-ms-flex: 100%;
		flex: 100%;
		max-width: 100%;
	}
}
