.services {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}
.example {
	cursor: pointer;
	position: relative;
	overflow: hidden;
	width: 25%;
	text-align: center;
}
.example .fadedbox {
	background-color: #1111116b;
	position: absolute;
	top: 0;
	left: 0;
	color: #fff;
	-webkit-transition: all 300ms ease-out;
	-moz-transition: all 300ms ease-out;
	-o-transition: all 300ms ease-out;
	-ms-transition: all 300ms ease-out;
	transition: all 300ms ease-out;
	opacity: 1;
	width: 360px;
	height: 100px;
	padding: 250px 20px;
}
.example:hover .fadedbox {
	opacity: 0.8;
}
.example img {
	transition: transform 0.5s ease;
}
.example:hover img {
	transform: scale(1.2);
}
.example .text {
	-webkit-transition: all 300ms ease-out;
	-moz-transition: all 300ms ease-out;
	-o-transition: all 300ms ease-out;
	-ms-transition: all 300ms ease-out;
	transition: all 300ms ease-out;
	transform: translateY(30px);
	-webkit-transform: translateY(30px);
}
.example .title {
	font-size: 2.5em;
	text-transform: uppercase;
	// opacity: 1;
	transition-delay: 0.2s;
	transition-duration: 0.3s;
}
.example:hover .title,
.example:focus .title {
	opacity: 1;
	transform: translateY(0px);
	-webkit-transform: translateY(0px);
}
