@mixin white-gradient {
	background: linear-gradient(
		to right,
		rgba(255, 255, 255, 1) 0%,
		rgba(255, 255, 255, 0) 100%
	);
}

$animationSpeed: 40s;

// Animation
@keyframes scroll {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(calc(-250px * 7));
	}
}

.customer-logos {
	padding: 80px 0;
}
.slick-track {
	width: 100% !important;
}

// Styling
.slider {
	// background: white;
	// box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);
	height: 150px;
	margin: auto;
	overflow: hidden;
	position: relative;
	width: 100% !important;

	&::before,
	&::after {
		// @include white-gradient;
		content: "";
		height: 100px;
		position: absolute;
		width: 200px;
		z-index: 2;
	}

	&::after {
		right: 0;
		top: 0;
		transform: rotateZ(180deg);
	}

	&::before {
		left: 0;
		top: 0;
	}

	.slide-track {
		animation: scroll $animationSpeed linear infinite;
		display: flex;
		width: calc(250px * 14);
		display: flex;
		align-items: baseline;
		justify-content: center;
	}

	.slide {
		height: 150px;
		width: 250px;
		padding: 20px;
	}
}




/* **********************************************
                Mobile view
**************************************************/
@media (max-width: 767px) {
	.customer-logos {
		padding: 10px 0;
	}
	.slider {
		.slide {
			height: 100px;
			width: 200px;
			padding: 5px;
		}
	}
}