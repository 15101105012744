.main-container {
	& > .first-fold {
		height: 100vh;
		width: 100vw;
		background-image: url("../../assets/images/19406401.jpg");
		background-color: #cccccc;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		& > .main-content {
			background-color: #0000006b;
			height: 100vh;
			width: 100vw;
			position: absolute;
			top: 0;
		}
		& > .content {
			height: 100vh;
			width: 100vw;
			justify-content: center;
			width: 100%;
			align-items: center;
			display: flex;
			flex-flow: column;
			& > .head {
				letter-spacing: 24px;
				font-size: 73px;
				font-weight: 300;
				& > span {
					color: #b3282b !important;
					font-weight: bold !important;
					padding-left: 24px;
				}
			}
			& > .main-text {
				font-size: 24px !important;
				line-height: 100px !important;
				font-weight: 300;
				letter-spacing: 3px;
				color: #fff;
				z-index: 10;
				margin-top: 220px;
			}
		}
	}
	.first-fold:before {
		content: "";
		position: absolute;
		background: rgba(0, 0, 0, 0.5);
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 10;
		height: calc(100vh + 10px);
	}
	& > .third-fold {
		& > .services-sec {
			padding: 50px 5%;
		}
	}
	& > .sixth-fold {
		// padding: 120px 0 0 0;
	}
	& > .seventh-fold {
		padding: 120px 5%;
		background-color: #f4f8f8;
		position: relative;
		& > .bg-color {
			height: 180px;
			width: 100%;
			background-color: #b3282b;
			position: absolute;
			top: 350px;
			left: 0;
		}
	}
	& > .counter-fold {
		padding: 80px 5%;
		background-color: #f4f8f8;
		position: relative;
		& > .bg-color {
			height: 180px;
			width: 100%;
			background-color: #b3282b;
			position: absolute;
			top: 350px;
			left: 0;
		}
	}
}

.main-video {
	position: absolute;
	// background: rgba(0, 0, 0, 0.5);
	top: 50%;
	left: 50%;
	-webkit-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
	width: 100vw;
	height: auto;
	overflow: hidden;
}

.marq-sec {
	// margin-top: 80px;
	min-height: 20px;
	background-color: #f4f8f8;
	padding: 50px 0 10px 0;
}

/* **********************************************
                Mobile view
**************************************************/
@media (max-width: 767px) {
	.main-container {
		& > .counter-fold {
			padding: 40px 5%;
			& > .container {
				flex-wrap: wrap;
				& > .item {
					width: 100%;
					border: none;
					& > .count-number {
						& > span {
							font-size: 30px;
						}
					}
					& > .count-descr {
						& > .count-title {
							font-size: 14px;
						}
					}
				}
			}
		}
		& > .first-fold {
			& > .main-video {
				width: auto;
				height: 100vh;
			}
			& > .content {
				& > .textcontent {
					top: 40%;
					& > .textcontent_text {
						font-size: 20px;
						padding: 0 30px;
						height: 160px;
					}
					& > .textcontent__container {
						height: 90px;
						padding: 0 30px;
						letter-spacing: 1px;
						& > .textcontent__container__list {
							& > .textcontent__container__list__item {
								line-height: 45px;
								font-size: 40px;
							}
						}
					}
				}
				& > .main-text {
					margin-top: 310px;
					font-size: 20px !important;
				}
			}
		}
		& > .second-fold {
			margin-bottom: 80px;
			& > .about-content {
				margin-top: 50px;
				margin-bottom: 0px;
				& > .image {
					width: 100%;
					& > video {
						height: 250px;
					}
				}
				& > .content {
					font-size: 16px;
				}
			}
		}
		& > .third-fold {
			height: 535px;
			& > .swiper-container {
				height: auto;
				& > .swiper-wrapper {
					& > .swiper-slide {
						& > div {
							& > .gallery-wrap {
								flex-wrap: wrap;
								width: 100%;
								height: 40vh;
								& > .image-item {
									width: 50%;
									& > .content {
										padding: 20px;
										& > h4 {
											font-size: 16px;
											margin-top: 50px;
										}
										& > .c-desc {
											font-size: 10px;
										}
										& > .know-more-btn {
											position: relative;
											bottom: 0px;
											margin-top: 25px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
		& > .sixth-fold {
			& > .services {
				& > .example {
					width: 50%;
					& > img {
						height: 170px;
					}
					& > .fadedbox {
						width: 100%;
						height: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
						padding: 0px;
						& > .title {
							font-size: 1em;
							font-weight: 600;
						}
						& > .text {
							margin-top: 0px;
						}
					}
				}
			}
		}
		& > .seventh-fold {
			padding: 20px 5%;
			background-color: #f4f8f8;
			position: relative;
			& > .aos-animate {
				& > .swiper {
					height: 350px !important;
					& > .swiper-wrapper {
						& > .swiper-slide {
							& > .video-title {
								padding: 10px;
							}
							& > video {
								height: 350px;
							}
						}
					}
				}
			}
			& > .bg-color {
				height: 180px;
				width: 100%;
				top: 245px;
			}
		}
		& > .eight-fold {
			& > .first-fold-b {
				& > .bottom-content {
					flex-wrap: wrap;
					& > .left-content {
						border: none;
						width: 100%;
						height: 250px;
						& > .left-sec {
							margin-top: 0px;
							& > .comp-name {
								margin: 0px;
								& > h3 {
									font-size: 20px;
								}
								& > .comp-text {
									margin-bottom: 5px;
									font-size: 12px;
								}
							}
						}
						& > .btn-bottom {
							& > .know-btn {
								margin-top: 25px;
							}
						}
					}
					& > .right-content {
						width: 100%;
						height: 300px;
						padding: 0px;
						& > .slideshow {
							font-size: 9px;
							& > .slideshowDots {
								& > .slideshowDot {
									width: 10px;
									height: 10px;
								}
							}
						}
					}
				}
			}
		}
	}
}
