.details {
	display: flex;
	align-items: baseline;
	& > .icon {
		width: 50px;
		& > img {
			// width: 100%;
			width: 25px;
			height: 25px;
		}
	}
	& > .detail {
		margin-left: 20px;
	}
}
