body {
  border: 0 none;
  font-size: 100%;
  margin: 0;
  outline: 0 none;
  padding: 0;
  vertical-align: baseline;
  font-size: 14px;
  line-height: 25px;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif !important;
  font-weight: normal;
  color: #7e8082;
  background-color: #fff;
  -webkit-font-smoothing: antialiased !important;
  -webkit-text-size-adjust: 100% !important;
}

.cp {
  cursor: pointer;
}

iframe {
  display: block;
  background: #000;
  border: none;
  height: 100vh;
  width: 100vw;
}