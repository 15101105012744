.item-details {
	& > .icon {
		width: 100%;
		display: flex;
		& > img {
			width: 50px;
		}
		& > h4 {
			margin-left: 20px;
		}
	}
	& > .detail {
		margin-left: 70px;
	}
}
