.service-container {
	& > .s-head {
		// background-color: #eee;
		margin-top: 0;
		font-size: 24px;
		line-height: 25px;
		letter-spacing: 5px;
		font-weight: 400;
		padding: 60px 5%;
		height: 40vh;
		width: 100vw;
		background-image: url("../../assets/images/coffee-bg.jpg");
		background-color: #cccccc;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		color: #fff;
		& > .s-title {
			font-size: 40px !important;
			line-height: 52px !important;
			padding: 80px 5%;
			text-align: center;
			margin: auto;
		}
	}

	& > .our-services {
		// margin: 0 5%;
		background-color: #eee;
		& > .s-title-head {
			// padding-top: 50px;
			// padding-bottom: 30px;
			padding: 50px 0;
			text-align: center;
			& > .os-title {
				font-size: 32px;
				line-height: 70px;
				color: #323336;
				font-weight: 500;
			}
			& > .os-filter {
				list-style: none;
				padding: 0;
				font-size: 13px;
				text-transform: uppercase;
				letter-spacing: 2px;
				color: #555;
				cursor: default;
				& > div {
					display: inline-block;
					margin: 0 21px;
					margin-bottom: 5px;
					color: #999;
					text-decoration: none;
					border-bottom: 1px solid transparent;
				}
				& > .filter {
					color: #666;
					// border-color: #323336;
					cursor: pointer;
				}
				& > .active {
					color: #b3282b;
					border-color: #323336;
					cursor: pointer;
					font-weight: bold;
				}
			}
		}
	}
	& > .s-details {
		display: flex;
		background-color: #eee;
		// margin: 120px 0;
		& > .img {
			width: 40%;
			& > img {
				width: 100%;
				min-height: 770px;
			}
		}
		& > .service-details {
			width: 60%;
			padding: 20px 100px;
			& > .title {
				font-size: 32px;
				line-height: 45px;
				margin: 40px 0 32px 0;
				font-weight: 100;
			}
			& > .desc {
				font-size: 14px;
				line-height: 25px;
				color: #7e8082;
			}
		}
	}
}

.service {
	width: 45%;
	padding: 10px 0px;
}
.service-d {
	display: flex;
	flex-wrap: wrap;
	margin-top: 50px;
}

.our-service-items {
	display: flex;
	flex-wrap: wrap;
	margin-top: 50px;
	justify-content: center;
	margin-bottom: 100px;
}

.our-service {
	width: 28%;
	padding: 10px 25px;
}

.services-img {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}

.fade-div {
	animation: fade-in 2s;
}

@keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}


/* **********************************************
                Mobile view
**************************************************/
@media (max-width: 767px) {
	.service-container{
		&>.s-head{
			&>.s-title{
				font-size: 30px !important;
				line-height: 45px !important;
				padding: 45px 0%;
				text-align: center;
				margin: auto;
			}
		}
		&>.s-details{
			flex-direction: column;
			display: flex;
			align-items: center;
			justify-content: center;
			&>.img{
				&>img{
					width: 100%;
					min-height: 150px;
					border-radius: 50%;
				}
			}
			&>.service-details{
				padding: 8px 10px;
				width: 100%;
				&>.title {
					font-size: 18px;
					line-height: 30px;
					margin: 0px 0 18px 0;
					font-weight: 100;
				}
				&>.service-d{
					&>.service{
						&>.details{
							&>.detail{
								margin-left: 5px;
								&>h4{
									font-size: 16px;
								}
							}
						}
					}
				}
			}
		}
	}
}