.sec-title {
    // margin: 0;
    padding: 15px 25px;
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    color: #0e130c;
    // font-weight: bold;
    // border-left: #0e130c 2px solid;
    text-align: center;
  &>span {
    // font-weight: bold;
    // color: #b3282b !important
    color: #0e130c;
  }
}