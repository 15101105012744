.view {
	height: 100%;
}
.scrolling-navbar {
	-webkit-transition: background 0.5s ease-in-out, padding 0.5s ease-in-out;
	-moz-transition: background 0.5s ease-in-out, padding 0.5s ease-in-out;
	transition: background 0.5s ease-in-out, padding 0.5s ease-in-out;
}

.top-nav-collapse {
	background-color: #1c2331;
}

.vc,
.vc-item,
.active {
	height: 100%;
}

.vc-inner {
	height: 100%;
}

.flex-center {
	color: #fff;
}

.vc-caption {
	height: 100%;
	padding-top: 7rem;
}

.video-title {
	width: 100%;
	z-index: 12;
	background-color: #04040466;
	position: absolute;
	top: 0;
	padding: 20px;
	color: #fff;
	font-size: 16px;
}
