header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex !important;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  height: 75px;
  z-index: 100 !important;
  &>.logo {
    padding-left: 5%;
  }
  &>.navigation {
    text-transform: uppercase;
    display: flex;
    padding-right: 5%;
    &>div{
      padding-left: 48px;
      letter-spacing: 1px;
      cursor: pointer;
      color: #fff;
    }
  }
  &>a{
    display: none;
  }
  &>.smallnav {
    text-transform: uppercase;
    display: flex;
    padding-right: 5%;
    &>div{
      padding-left: 32px;
      letter-spacing: 1px;
      cursor: pointer;
      color: #333;
    }
  }
}

.small{
  background-color: #fff;
  // height: 60px !important;
  transition: padding 300ms ease;
}

/* **********************************************
                Mobile view
**************************************************/
@media (max-width: 767px) {
  header {
    overflow: hidden;
    background-color: #000000;
    position: relative;
    &>.navlist{
      display: none;
      background-color: #000;
      position: fixed;
      top: 70px;
      left: 215px;
      right: 1px;
      &>div{
        margin-top: 10px;
        margin-bottom: 10px;
        padding-left: 25px;
      }
    }
    &> a {
      text-decoration: none;
      display: block;
      position: absolute;
      right:0;
      top: 20;
        &>.icon {
          display: block;
          margin-right: 10px;
        }
    }
  }
}