.about-content {
  padding: 0 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: column;
  margin-bottom: 80px;
  &>.content {
    color: #0e130c;
    font-size: 21px;
    line-height: 32px;
    font-weight: 100;
    width: 80%;
    text-align: center;
  }
  &>.image {
    width: 50%;
    &>img {
      width: 100%;
    }
  }
}
iframe {
  width: 100% !important;
  height: 600px;
}