.team-section {
	display: flex;
	width: 100%;
	margin-top: 50px;
	background-color: #fff;
	.main {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		// background-color: #f4f8f8;
	}
	.profile-card {
		display: flex;
		align-items: center;
		width: 50%;
		padding: 40px 0;
		& > .img {
			width: 220px;
			height: 220px;
			border-radius: 50%;
			box-shadow: 0 0 22px #3336;
			margin: 0 50px;
			& > img {
				width: 100%;
				height: 100%;
				border-radius: 50%;
			}
		}
	}
	.caption h3 {
		font-size: 21px;
		font-family: sans-serif;
		color: #b3282b;
	}
	.caption p {
		font-size: 15px;
		color: #0c52a1;
		font-family: sans-serif;
		margin: 2px 0 9px 0;
	}
	.caption .social-links a {
		color: #333;
		margin-right: 15px;
		font-size: 21px;
		transition: 0.6s;
	}
	.social-links a:hover {
		color: #0c52a1;
	}
}

/* **********************************************
                Mobile view
**************************************************/
@media (max-width: 767px) {
	.team-section {
		.caption h3 {
			font-size: 13px;
			font-family: sans-serif;
			color: #b3282b;
			margin-top: 10px;
			margin-bottom: 2px;
		}
		.caption span {
			font-size: 10px;
			font-family: sans-serif;
			line-height: 1.2;
		}
		.caption p {
			font-size: 15px;
			color: #0c52a1;
			font-family: sans-serif;
			margin: 0px 0 5px 0;
		}
		.caption .social-links a {
			color: #333;
			margin-right: 15px;
			font-size: 15px;
			transition: 0.6s;
		}
		.social-links a:hover {
			color: #0c52a1;
		}
		.profile-card {
			display: flex;
			align-items: center;
			width: 100%;
			padding: 10px 0;
			& > .img {
				width: 90px;
				height: 80px;
				border-radius: 50%;
				box-shadow: 0 0 22px #3336;
				margin: 0 5px;
				& > img {
					width: 100%;
					height: 100%;
					border-radius: 50%;
				}
			}
		}
	}
}
